import { getCookie } from 'cookies-next';
import axios, {
	type AxiosError,
	type AxiosInstance,
	type AxiosRequestConfig,
} from 'axios';
import { toast } from 'sonner';
import { useUserStore } from '@/hooks/use-user-store';
import { navigate } from '@/app/actions';
import type {
	Response,
	LoginForm,
	LoginResponse,
	CategoryResponse,
	ProductResponse,
	SupplierResponse,
	SupplierGroupResponse,
	Warehouse,
	InventoryResponse,
	WarehouseResponse,
	IncomeResponse,
	MonthFilter,
	YearFilter,
	TotalExpenseResponse,
	TotalIncomeResponse,
	PurchaseResponse,
	AddPurchaseRequest,
	RecipeResponse,
	MaterialResponse,
	MaterialMutation,
	RecipeMutation,
	ProductMutation,
	WarehouseTransferResponse,
	StockEntriesResponse,
	UserResponse,
	EditUserMutation,
	UserMutation,
	UnitResponse,
	EditProductMutation,
	EditMaterialMutation,
	RecipeItemResponse,
	SearchInventoryResponse,
	NotificationResponse,
	StockRequestResponse,
	Login,
	SectionResponse,
	ErrorResponse,
	RecipeHistoryResponse,
	RecipeActionResponse,
	SearchProducts,
	SearchProductsResponse,
	ManuelCountMutation,
	SingleProductResponse,
	Material,
	MovementResponse,
	StockSaleResponse,
	SaleUpdateMutation,
	Recipe,
} from '@/types/types';

// API base configuration
const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL;
const CND_URL = 'https://cdn.veritfy.com';

// Create API instance
const createApiInstance = (): AxiosInstance => {
	const instance = axios.create({
		baseURL: API_BASE_URL,
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	});

	// Request interceptor
	instance.interceptors.request.use(
		(config) => {
			const token = getCookie('authToken');
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}
			return config;
		},
		(error) => Promise.reject(error),
	);

	// Response interceptor
	instance.interceptors.response.use(
		(response) => response,
		(error) => {
			if (error.response?.status === 401) {
				toast.error('Oturumunuzun süresi doldu', {
					duration: 2000,
				});
				useUserStore.getState().clearUser();
				setTimeout(() => navigate('/login'), 400);
			}
			return Promise.reject(error);
		},
	);

	return instance;
};

export const api = createApiInstance();

// Generic API call function
const apiCall = async <T, D = undefined>(
	method: 'get' | 'post' | 'put' | 'delete' | 'patch',
	url: string,
	data?: D,
	config?: AxiosRequestConfig,
): Promise<T> => {
	try {
		const response = await api[method]<T>(url, data, config);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const axiosError = error as AxiosError<ErrorResponse>;
			if (axiosError.response) {
				throw axiosError.response.data;
			}
		}
		throw error;
	}
};

// API endpoints
// API endpoints
const endpoints = {
	category: '/category',
	unit: '/unit',
	login: '/login',
	user: '/user',
	income: '/income',
	recipe: '/recipe',
	recipeUpdate: '/recipe/update',
	products: '/products',
	product: '/product',
	productAllType: '/product/all-type',
	warehouse: '/warehouse',
	warehouses: '/warehouses',
	supplier: '/supplier',
	purchase: '/purchase',
	material: '/material',
	recipeUse: '/recipe/use',
	recipeHistory: '/recipe/history',
	recipeItems: '/recipe/items',
	supplierGroup: '/supplier/group',
	transfersWarehouses: '/transfers/warehouses',
	transferStokEntries: '/transfers/stock-entries',
	transferStockCounts: '/transfers/stock-counts',
	transferStockSalesReceipts: '/transfers/stock-sales-receipts',
	transferStockSales: '/transfers/stock-sales',
	transferStockCorrections: '/transfers/stock-corrections',
	transferRecipeActions: '/transfers/recipe-actions',
	transferRecipeAction: '/transfers/recipe-action',
	inventory: '/inventory',
	totalIncome: '/total-income',
	totalExpense: '/total-expense',
	warehouseRecipe: '/warehouse/recipe',
	notification: '/notification',
	multipleNotifications: '/notification/multiple',
	allNotifications: '/notification/all',
	mainWarehouse: '/warehouse/main',
	getMainWarehouse: '/main-warehouse',
	unitConvert: '/unit/convert',
	recipeRollback: '/recipe/rollback',
	purchaseDetails: '/purchase/details',
	inventoryCount: '/inventory/count',
	inventoryTransfer: '/inventory/transfer',
	inventoryRequests: '/inventory/requests',
	inventorySearch: '/inventory/search',
	inventorySearchAll: '/inventory/search/all',
	inventorySearchMultiple: '/inventory/search/multiple',
	inventoryDecraseMultipleBarcode: '/inventory/decrease/multiple/barcode',
	incentoryIncreaseMultipleBarcode: '/inventory/increase/multiple/barcode',
	inventoryApproveRequest: '/inventory/approve-adjustment',
	inventoryRejectAdjustment: '/inventory/reject-adjustment',
	inventoryRequestAdjustment: '/inventory/request-adjustment',
	inventorySections: '/inventory/sections',
	inventorySale: '/inventory/sale',
	inventoryReturn: '/inventory/return',
	inventorySaleUpdate: '/inventory/sale/update',
};

// API functions
export async function loginUser(user: LoginForm) {
	return await apiCall<LoginResponse, LoginForm>(
		'post',
		endpoints.login,
		user,
	);
}

export async function fetchCategories() {
	return await apiCall<CategoryResponse>('get', endpoints.category);
}

export async function addCategory(data: {
	categoryName: string;
	parentCategoryId?: number | null;
}) {
	return await apiCall<typeof data, typeof data>(
		'post',
		endpoints.category,
		data,
	);
}

export async function deleteCategory(data: { categoryId: number }) {
	return await apiCall<Response>(
		'delete',
		`${endpoints.category}/${data.categoryId}`,
	);
}

export async function fetchProducts({ searchParam }: { searchParam?: string }) {
	return await apiCall<ProductResponse>(
		'get',
		`${endpoints.products}?${searchParam ? `${searchParam}` : ''}`,
	);
}

export async function fetchProduct({
	productId,
}: {
	productId: number;
}) {
	return await apiCall<SingleProductResponse>(
		'get',
		`${endpoints.product}/${productId}`,
	);
}

export async function addProduct(data: ProductMutation) {
	return await apiCall<Response, typeof data>(
		'post',
		endpoints.product,
		data,
	);
}

export async function deleteProduct(data: { productId: number }) {
	return await apiCall<Response, typeof data>(
		'delete',
		`${endpoints.product}/${data.productId}`,
	);
}

export async function fetchWarehouses() {
	return await apiCall<WarehouseResponse>('get', endpoints.warehouses);
}

export async function addWarehouse(data: { warehouseName: string }) {
	return await apiCall<Response, typeof data>(
		'post',
		endpoints.warehouse,
		data,
	);
}

export async function deleteWarehouse(data: { warehouseId: number }) {
	return await apiCall<Response, typeof data>(
		'delete',
		endpoints.warehouse,
		data,
	);
}

export async function fetchSuppliers() {
	return await apiCall<SupplierResponse>('get', endpoints.supplier);
}

export async function fetchSupplierGroups() {
	return await apiCall<SupplierGroupResponse>('get', endpoints.supplierGroup);
}

export async function addSupplier(data: {
	SupplierName: string;
	Address: string;
	SupplierGroupID: number;
	TaxNumber: string;
	Phone: string;
}) {
	return await apiCall<Response, typeof data>(
		'post',
		endpoints.supplier,
		data,
	);
}

export async function addSupplierGroup(data: { GroupName: string }) {
	return await apiCall<Response, typeof data>(
		'post',
		endpoints.supplierGroup,
		data,
	);
}

export async function deleteSupplier(data: { supplierId: number }) {
	return await apiCall<Response, typeof data>(
		'delete',
		endpoints.supplier,
		data,
	);
}

export async function fetchInventoryWarehouse(
	warehouseID: number,
	searchParam?: string,
) {
	return await apiCall<InventoryResponse>(
		'get',
		`${endpoints.inventory}/${warehouseID}${searchParam ? `${searchParam}` : ''}`,
	);
}

export async function fetchInventoryWarehouseRequest(warehouseID: number) {
	return await apiCall<StockRequestResponse>(
		'get',
		`${endpoints.inventoryRequests}/${warehouseID}`,
	);
}

export async function fetchMainWarehouse() {
	return await apiCall<Warehouse>('get', endpoints.getMainWarehouse);
}

export async function makeMainWarehouse(data: {
	warehouseId: number;
}) {
	return await apiCall<Response>(
		'put',
		`${endpoints.mainWarehouse}/${data.warehouseId}`,
	);
}

export async function fetchIncome(
	selectedMonth?: MonthFilter,
	selectedYear?: YearFilter,
) {
	return await apiCall<IncomeResponse, null>('get', endpoints.income, null, {
		params: { month: selectedMonth, year: selectedYear },
	});
}

export async function deleteIncome(data: { incomeId: number }) {
	return await apiCall<Response>(
		'delete',
		`${endpoints.income}/${data.incomeId}`,
	);
}
export async function fetchTotalIncome(
	selectedMonth?: MonthFilter,
	selectedYear?: YearFilter,
) {
	return await apiCall<TotalIncomeResponse, null>(
		'get',
		endpoints.totalIncome,
		null,
		{
			params: { month: selectedMonth, year: selectedYear },
		},
	);
}

export async function fetchTotalExpense(
	selectedMonth?: MonthFilter,
	selectedYear?: YearFilter,
) {
	return await apiCall<TotalExpenseResponse, null>(
		'get',
		endpoints.totalExpense,
		null,
		{
			params: { month: selectedMonth, year: selectedYear },
		},
	);
}

export async function addIncome(data: {
	amount: number;
	description: string;
	date: string;
}) {
	return await apiCall<Response, typeof data>('post', endpoints.income, data);
}

export async function fetchPurchases(
	selectedMonth?: MonthFilter,
	selectedYear?: YearFilter,
) {
	return await apiCall<PurchaseResponse, null>(
		'get',
		endpoints.purchase,
		null,
		{
			params: { month: selectedMonth, year: selectedYear },
		},
	);
}

export async function fetchPurchaseDetails(purchaseId: number) {
	return await apiCall<PurchaseResponse>(
		'get',
		`${endpoints.purchaseDetails}?purchaseId=${purchaseId}`,
	);
}

export async function addPurchase(data: AddPurchaseRequest) {
	return await apiCall<Response, typeof data>(
		'post',
		endpoints.purchase,
		data,
	);
}

export async function deletePurchase(data: { purchaseId: number }) {
	return await apiCall<Response, typeof data>(
		'delete',
		endpoints.purchase,
		data,
	);
}

export async function requestPurchase(data: {
	productId: number;
	quantity: number;
	warehouseId: number;
	userId: number;
}) {
	return await apiCall<Response, typeof data>(
		'post',
		`${endpoints.purchase}/request`,
		data,
	);
}

export async function approvePurchase(data: { requestId: number }) {
	return await apiCall<Response, typeof data>(
		'patch',
		`${endpoints.purchase}/approve`,
		data,
	);
}

export async function fetchRecipes() {
	return await apiCall<RecipeResponse>('get', endpoints.recipe);
}

export async function fetchRecipe(recipeId: number) {
	return await apiCall<Response<Recipe>>(
		'get',
		`${endpoints.recipe}/${recipeId}`,
	);
}

export async function fetchWarehouseRecipes(warehouseId: number) {
	return await apiCall<RecipeResponse>(
		'get',
		`${endpoints.warehouseRecipe}/${warehouseId}`,
	);
}

export async function useRecipe(data: {
	recipeId: number;
	multiplier: number;
}) {
	return await apiCall<Response, typeof data>(
		'post',
		endpoints.recipeUse,
		data,
	);
}

export async function fetchInventoryCount(warehouseId: number) {
	return await apiCall<Response>(
		'get',
		`${endpoints.inventoryCount}/${warehouseId}`,
	);
}

export async function fetchWarehouseTransfers() {
	return await apiCall<WarehouseTransferResponse>(
		'get',
		endpoints.transfersWarehouses,
	);
}

export async function fetchTransferStockEntries() {
	return await apiCall<StockEntriesResponse>(
		'get',
		endpoints.transferStokEntries,
	);
}

export async function transferInventory(data: {
	fromWarehouseId: number;
	toWarehouseId: number;
	fromSectionId: number;
	toSectionId: number;
	productId: number;
	quantity: number;
}) {
	return await apiCall<Response, typeof data>(
		'post',
		endpoints.inventoryTransfer,
		data,
	);
}

export async function approveInventoryRequest(data: {
	requestId: number;
	warehouseId: number;
}) {
	return await apiCall<Response, typeof data>(
		'patch',
		`${endpoints.inventoryApproveRequest}/${data.warehouseId}`,
		data,
	);
}

export async function rejectInventoryRequest(data: {
	requestId: number;
	warehouseId: number;
}) {
	return await apiCall<Response, typeof data>(
		'patch',
		`${endpoints.inventoryRejectAdjustment}/${data.warehouseId}`,
		data,
	);
}

export async function convertUnit(data: {
	productId: number;
	unit: string;
	quantity: number;
}) {
	return await apiCall<Response, typeof data>(
		'post',
		endpoints.unitConvert,
		data,
	);
}

export async function fetchMaterials({
	searchParam,
}: { searchParam?: string }) {
	return await apiCall<MaterialResponse>(
		'get',
		`${endpoints.material}?${searchParam ? `${searchParam}` : ''}`,
	);
}

export async function addMaterial(data: MaterialMutation) {
	return await apiCall<Response, typeof data>(
		'post',
		endpoints.material,
		data,
	);
}

export async function addRecipe(data: RecipeMutation) {
	return await apiCall<Response, typeof data>('post', endpoints.recipe, data);
}

export async function updateRecipe(
	data: RecipeMutation & { recipeId: number },
) {
	return await apiCall<Response, typeof data>(
		'post',
		`${endpoints.recipe}/update/${data.recipeId}`,
		data,
	);
}

export async function deleteRecipe(data: { recipeId: number }) {
	return await apiCall<Response>(
		'delete',
		`${endpoints.recipe}/${data.recipeId}`,
	);
}

export async function fetchUnits() {
	return await apiCall<UnitResponse>('get', endpoints.unit);
}

export async function fetchUsers() {
	return await apiCall<UserResponse>('get', endpoints.user);
}

export async function editUser(data: EditUserMutation) {
	return await apiCall<Response, typeof data>(
		'put',
		`${endpoints.user}/${data.id}`,
		data,
	);
}

export async function addUser(data: UserMutation) {
	return await apiCall<Response, typeof data>('post', endpoints.user, data);
}

export async function deleteUser(data: { userId: number }) {
	return await apiCall<Response>(
		'delete',
		`${endpoints.user}/${data.userId}`,
	);
}

export async function deleteUnit(data: { unitId: number }) {
	return await apiCall<Response>(
		'delete',
		`${endpoints.unit}/${data.unitId}`,
	);
}

export async function addUnit(data: {
	UnitName: string;
	ConversionRate: number;
	ParentID: number;
}) {
	return await apiCall<Response, typeof data>('post', endpoints.unit, data);
}

export async function editProduct(data: EditProductMutation) {
	return await apiCall<Response, typeof data>(
		'patch',
		`${endpoints.product}/${data.productId}`,
		data,
	);
}

export async function editMaterial(data: EditMaterialMutation) {
	return await apiCall<Response, typeof data>(
		'patch',
		`${endpoints.material}/${data.materialId}`,
		data,
	);
}

export async function deleteMaterial(data: { materialId: number }) {
	return await apiCall<Response>(
		'delete',
		`${endpoints.material}/${data.materialId}`,
	);
}

export async function requestStockIncrease(data: {
	quantity: number;
	productId: number;
	warehouseId: number;
}) {
	return await apiCall<
		Response,
		{
			quantity: number;
			productId: number;
			warehouseId: number;
			action: 'increase';
		}
	>('post', `${endpoints.inventoryRequestAdjustment}/${data.warehouseId}`, {
		...data,
		action: 'increase',
	});
}

export async function requestStockDecrease(data: {
	quantity: number;
	productId: number;
	warehouseId: number;
}) {
	return await apiCall<
		Response,
		{
			quantity: number;
			productId: number;
			warehouseId: number;
			action: 'decrease';
		}
	>('post', `${endpoints.inventoryRequestAdjustment}/${data.warehouseId}`, {
		...data,
		action: 'decrease',
	});
}

export async function correctStock(data: {
	productId: number;
	actualQuantity: number;
	warehouseId: number;
}) {
	return await apiCall<Response, typeof data>(
		'patch',
		`${endpoints.inventory}/${data.warehouseId}`,
		data,
	);
}

export async function fetchTransferStockCounts() {
	return await apiCall<Response>('get', endpoints.transferStockCounts);
}

export async function fetchTransferRecipeActions() {
	return await apiCall<Response>('get', endpoints.transferRecipeActions);
}

export async function fetchTransferStockCorrections() {
	return await apiCall<Response>('get', endpoints.transferStockCorrections);
}

export async function fetchRecipeItems(data: {
	recipeId: number;
	multiplier: number;
}) {
	return await apiCall<RecipeItemResponse, typeof data>(
		'post',
		`${endpoints.recipeItems}/${data.recipeId}`,
		data,
	);
}

export async function fetchInventorySearch(data: {
	barcode?: string;
	sku?: string;
}) {
	return await apiCall<SearchInventoryResponse, typeof data>(
		'post',
		endpoints.inventorySearchAll,
		data,
	);
}

export async function fetchStandardUnits() {
	return await apiCall<UnitResponse>('get', endpoints.unit);
}

export async function editCategory(data: {
	categoryId: number;
	categoryName: string;
	parentCategoryId?: number | null;
}) {
	return await apiCall<Response, typeof data>(
		'patch',
		`${endpoints.category}/${data.categoryId}`,
		data,
	);
}

export async function fetchNotifications() {
	return await apiCall<NotificationResponse>('get', endpoints.notification);
}
export async function markMultipleNotificationsAsRead(data: {
	notificationIds: number[];
}) {
	return await apiCall<Response, typeof data>(
		'patch',
		endpoints.multipleNotifications,
		data,
	);
}

export async function markNotificationsAsRead(data: {
	notificationId: number;
}) {
	return await apiCall<Response, typeof data>(
		'patch',
		endpoints.notification,
		data,
	);
}

export async function deleteNotification(data: { notificationId: number }) {
	return await apiCall<Response, { data: typeof data }>(
		'delete',
		endpoints.notification,
		{ data },
	);
}

export async function deleteSelectedNotifications(data: {
	notificationIds: number[];
}) {
	return await apiCall<Response, { data: typeof data }>(
		'delete',
		endpoints.multipleNotifications,
		{ data },
	);
}

export async function markAllNotificationsAsRead() {
	return await apiCall<Response>('patch', endpoints.allNotifications);
}

export async function fetchUnreadNotificationCount() {
	return await apiCall<Response>(
		'get',
		`${endpoints.notification}/unread-count`,
	);
}

export async function fetchWarehouseSections(data: { warehouseId: number }) {
	return await apiCall<SectionResponse>(
		'get',
		`${endpoints.inventorySections}/${data.warehouseId}`,
	);
}

export async function addInventorySection(data: {
	warehouseId: number;
	sectionName: string;
}) {
	return await apiCall<Response, typeof data>(
		'post',
		`${endpoints.inventorySections}`,
		data,
	);
}

export async function rollbackRecipe(data: { recipeMovementID: string }) {
	return await apiCall<Response, typeof data>(
		'post',
		endpoints.recipeRollback,
		{ recipeMovementID: data.recipeMovementID },
	);
}

export const fetchRecipesHistory = async () => {
	return await apiCall<RecipeHistoryResponse>('get', endpoints.recipeHistory);
};

export const fetchRecipeHistoryDetails = async (recipeMovementID: string) => {
	return await apiCall<RecipeActionResponse>(
		'get',
		`${endpoints.transferRecipeAction}/${recipeMovementID}`,
	);
};

export const fetchInventoryOfProduct = async (data: {
	barcode: string;
	warehouseId: number;
	sectionId: number;
}) => {
	return await apiCall<SearchProductsResponse, typeof data>(
		'post',
		`${endpoints.inventorySearch}`,
		{
			barcode: data.barcode,
			warehouseId: data.warehouseId,
			sectionId: data.sectionId,
		},
	);
};

export const decreaseMultipleBarcode = async (data: {
	warehouseId: number;
	movementType: number;
	products: {
		barcode: string;
		quantity: number;
		sectionId: number;
	}[];
}) => {
	return await apiCall<Response, typeof data>(
		'post',
		`${endpoints.inventoryDecraseMultipleBarcode}`,
		data,
	);
};

export const increaseMultipleBarcode = async (data: {
	warehouseId: number;
	movementType: number;
	products: {
		barcode: string;
		quantity: number;
		sectionId: number;
	}[];
}) => {
	return await apiCall<Response, typeof data>(
		'post',
		`${endpoints.incentoryIncreaseMultipleBarcode}`,
		data,
	);
};

export const manuelCount = async (data: ManuelCountMutation) => {
	return await apiCall<Response, typeof data>(
		'post',
		`${endpoints.inventory}/count/${data.warehouseId}`,
		data,
	);
};

export const uploadImage = async (data: FormData) => {
	const response = await fetch(`${CND_URL}/upload.php`, {
		method: 'POST',
		body: data,
	});
	return await response.json();
};

export const fetchWarehouse = async (warehouseId: number) => {
	return await apiCall<Response<Warehouse>>(
		'get',
		`${endpoints.warehouse}/${warehouseId}`,
	);
};

export const fetchMaterial = async (materialId: number) => {
	return await apiCall<Response<Material>>(
		'get',
		`${endpoints.material}/${materialId}`,
	);
};

export const fetchStockSales = async () => {
	return await apiCall<StockSaleResponse>(
		'get',
		endpoints.transferStockSalesReceipts,
	);
};

export const fetchStockSale = async (saleMovementID: string) => {
	return await apiCall<MovementResponse>(
		'get',
		`${endpoints.transferStockSales}/${saleMovementID}`,
	);
};

export const updateSale = async (data: SaleUpdateMutation) => {
	return await apiCall<Response, typeof data>(
		'post',
		`${endpoints.inventorySaleUpdate}`,
		data,
	);
};

export const returnMultiple = async (data: {
	warehouseId: number;
	products: {
		barcode: string;
		quantity: number;
		sectionId: number;
	}[];
}) => {
	return await apiCall<Response, typeof data>(
		'post',
		`${endpoints.inventoryReturn}/${data.warehouseId}`,
		data,
	);
};

export const addSale = async (data: {
	warehouseId: number;
	products: {
		barcode: string;
		quantity: number;
		sectionId: number;
	}[];
}) => {
	return await apiCall<Response, typeof data>(
		'post',
		`${endpoints.inventorySale}/${data.warehouseId}`,
		data,
	);
};

export const fetchProductsAllType = async () => {
	return await apiCall<ProductResponse>('get', endpoints.productAllType);
};
